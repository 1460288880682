import { FC } from 'react';

import Gap from 'bloko/blocks/gap';

import LightPage from 'src/app/layouts/LightPage';
import MagritteDefaultLayout from 'src/app/layouts/MagritteLayouts/DefaultLayout';
import CombinedLoginCards from 'src/components/AccountLogin/CombinedLoginCards/CombinedLoginCards';
import GridColumn from 'src/components/MagritteRedesignComponents/GridColumn';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import TinkoffBussinessErrorModal from 'src/components/SignInSignUpFlow/TinkoffBussinessErrorModal';
import useIsIframeView from 'src/hooks/useIsIframeView';
import { useSelector } from 'src/hooks/useSelector';

import AccountLoginLayout from 'src/pages/AccountLogin/AccountLoginLayout';

const AccountLoginPage: FC = () => {
    const isIframeView = useIsIframeView();
    const { errorMessage } = useSelector(({ errorPage }) => errorPage);

    const loginCards = (
        <GridColumn xs="4" s="8" m="12" l={'12'}>
            <div className="account-login-page">
                <GridColumn xs="4" s="6" m="6" l={'4'} container>
                    {errorMessage && <Gap bottom>{errorMessage}</Gap>}
                    <CombinedLoginCards />
                </GridColumn>
            </div>
        </GridColumn>
    );

    if (isIframeView) {
        return (
            <MagritteWrapper isEnabled>
                <LightPage>
                    {({ header, footer }) => (
                        <MagritteDefaultLayout>
                            <GridColumn xs="4" s="2" m="4" l="6">
                                <Gap sTop mTop lTop>
                                    {header}
                                </Gap>
                            </GridColumn>
                            <GridColumn xs="4" s="8" m="12" l="16" container>
                                <Gap xsTop>{loginCards}</Gap>
                            </GridColumn>
                            <GridColumn xs="4" s="8" m="12" l="16" container>
                                {footer}
                            </GridColumn>
                        </MagritteDefaultLayout>
                    )}
                </LightPage>
            </MagritteWrapper>
        );
    }

    return (
        <MagritteWrapper isEnabled>
            <AccountLoginLayout>
                {loginCards}
                <TinkoffBussinessErrorModal />
            </AccountLoginLayout>
        </MagritteWrapper>
    );
};

export default AccountLoginPage;
