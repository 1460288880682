import { useState, FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import AccountTypeSwitch from 'src/components/AccountLogin/components/AccountTypeSwitch/AccountTypeSwitch';
import Agreement from 'src/components/AccountLogin/components/Agreement/Agreement';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import ApplicantLoginCard from 'src/components/AccountLogin/CombinedLoginCards/ApplicantLoginCard';
import EmployerLoginCard from 'src/components/AccountLogin/CombinedLoginCards/EmployerLoginCard';

const AccountLogin: FC = () => {
    const loginTileType = useSelector(({ loginTileType }) => loginTileType);
    const [showAgreement, setShowAgreement] = useState(true);
    const [shouldShowAccountTypeSwitch, setShowAccountTypeSwitch] = useState(!loginTileType);
    const anonymousUserType = useSelector((state) => state.anonymousUserType);

    const applicantLogin = (
        <ApplicantLoginCard
            key="applicant_authorization"
            onSignUp={() => {
                setShowAgreement(false);
                setShowAccountTypeSwitch(false);
            }}
        />
    );
    const employerLogin = (
        <EmployerLoginCard key="employer_authorization" onNextStep={() => setShowAccountTypeSwitch(false)} />
    );
    const loginCard = anonymousUserType === UserType.Applicant ? applicantLogin : employerLogin;

    return (
        <>
            {loginCard}
            <VSpacing default={24} />
            {shouldShowAccountTypeSwitch && <AccountTypeSwitch />}
            {showAgreement && (
                <>
                    <VSpacing default={24} />
                    <Agreement isApplicant={anonymousUserType === UserType.Applicant} />
                </>
            )}
        </>
    );
};

export default AccountLogin;
